@font-face {
    font-family: 'GAU_pop_magic';
    src: url('./GAU_pop_magic.TTF') format('truetype');
}

@font-face {
    font-family: 'Octuple_max_demo';
    src: url('./Octuple-max-demo.ttf') format('truetype');
}

@font-face {
    font-family: 'PANNETJE';
    src: url('./PANNETJE.TTF') format('truetype');
}

@font-face {
    font-family: 'PixdorTwo';
    src: url('./PixdorTwo.ttf') format('truetype');
}