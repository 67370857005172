@import url('../assets/customFonts.css');

:root {
    --primary-color: #ffdffa;
    --content-width: 1000px;
    /* Define a variable for the primary color */
}

.top-logo {
    height:75px;
    margin-top: -12px;
    margin-bottom: -6px;
    padding-bottom: 2px;
    /* margin-right: auto; */
    transition: 0.3s;
}

.top-logo:hover{
    cursor: pointer;
    transform: scale(1.05);
}

.top-bar {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    background-color: var(--primary-color);
    padding-top: 5px;
    /* display: flex; */
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    /* Keep left padding */
    /* Keep right padding */
    /* justify-content: space-between; */
    /* Adjust the alignment of logo and menu */
}

.top-bar .top-bar-content {
    width: 80vw;
    max-width: var(--content-width);
    display: flex;
    margin:auto;
}

.top-bar .top-bar-content .top-menu {
    display: flex;
    margin-left: auto;
    height: inherit;
}

.top-bar .top-bar-content .top-menu-item{
    font-family: 'PANNETJE';
    font-size: 23px;
    width: 160px;
    height: 71px;
    margin-top: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.15s;
}

.top-bar .top-bar-content .top-menu-item.active{
    background-color: rgb(255, 184, 253);
}

.top-bar .top-bar-content .top-menu-item:hover {
    background-color: rgb(251, 190, 248);
    cursor: pointer;
    /* font-size: 30px; */
}

.top-bar .top-bar-content .top-menu-item.active:hover {
    background-color: rgb(251, 195, 249);
    cursor:auto;
}

.home-wrapper{
    margin-top: 85px;
    width: 100%;
    margin-bottom: 20px;
}

.home-content{
    margin:auto;
    width: 80vw;
    max-width: var(--content-width);
}

.ft-bar {
    border-top-style: solid;
    border-top-width: 2px;
    background-color: var(--primary-color);
    padding-top: 5px;
    /* display: flex; */
    top: 0;
    width: 100%;
    z-index: 999;
    /* Keep left padding */
    /* Keep right padding */
    /* justify-content: space-between; */
    /* Adjust the alignment of logo and menu */
}

.ft-bar .ft-bar-content {
    width: 80vw;
    display: flex;
    margin: auto;
    max-width: var(--content-width);
}