@import url('../../assets/customFonts.css');

.news-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.news-card {
    background-color: #fff;
    border-style: solid;
    overflow: hidden;
    border-width: 2px;
}

.news-card:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.news-thumbnail {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom-style: solid;
    border-width: 2px;
    overflow: hidden;
}


.news-content {
    padding: 15px;
}

.news-title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.news-description {
    margin: 10px 0;
    font-size: 14px;
}

.news-date {
    margin-left: auto;
    font-size: 12px;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
}

.news-author {
    font-size: 12px;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
}

.post-meta{
    display: flex;
    margin-bottom: -15px;
}