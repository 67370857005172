.a-wrapper{
    display: flex;
    border-style: solid;
    border-width: 2px;
    background-color: #ffdffa;
}

.a-article {
    width: 64%;
    padding: 30px;
    padding-top: 0px;
}

.a-article h1 {
    /* text-align: center; */
    font-size: 28px;
}

.a-sidebar {
    width: 34%;
}

.a-thumbnail-wrapper{
    display: block;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 15px;
    padding-bottom: 5px;
}

.a-thumbnail-wrapper .a-thumbnail {
    width: 60vw;
    max-width: 600px;
    /* Adjust the width as needed */
    height: calc(60vw * 9 / 16);
    max-height: 337.5px;
    /* This maintains a 16:9 aspect ratio */
    object-fit: cover;
    margin: auto;
    border-style: solid;
    border-width: 2px;
}

.a-content {
    font-size: 20px;
    line-height: 1.5;
}

.a-thumb-src {
    font-size: 12px;
    color: #343434;
    margin-top: 10px;
}