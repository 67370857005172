@keyframes glitter {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    50% {
        opacity: 1;
        transform: scale(1.5);
    }

    100% {
        opacity: 0;
        transform: scale(0.5);
    }
}

.glitter {
    position: absolute;
    animation: glitter 2s infinite;
    pointer-events: none;
}